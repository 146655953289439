<template>
  <div class="d-flex align-center justify-center">
    <v-dialog v-model="isDialogVisible" persistent width="500">
      <template #activator="{ on, attrs }">
        <v-btn color="error" x-large v-bind="attrs" v-on="on"> Delete </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline"> Delete this {{ type }}? </v-card-title>
        <v-card-text>
          This will permanently delete the {{ type }} <em>{{ title }}</em
          >.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" outlined @click="closeDialog()">Cancel </v-btn>
          <v-btn color="error" @click="performDeleteAction">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DeleteButton",
  props: {
    deleteAction: {
      type: Function,
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isDialogVisible: false,
    }
  },
  methods: {
    closeDialog() {
      this.isDialogVisible = false // eslint-disable-line no-param-reassign
    },
    performDeleteAction() {
      if (this.deleteAction) {
        this.deleteAction()
        this.closeDialog()
      }
    },
  },
}
</script>
