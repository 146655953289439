<template>
  <div id="note-configuration">
    <div class="page-title px-4 mb-4">
      <h2>
        <span class="me-2">Note ID #{{ note.note_id }} - {{ note.title }}</span>
      </h2>
      <save-button :save-action="saveNoteConfig" />
    </div>

    <v-divider class="mt-4 mb-10"></v-divider>

    <v-card>
      <v-row v-if="is_loading" class="inline-loader">
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
      </v-row>

      <v-row v-else>
        <v-card-title class="mb-4">
          <h2 class="text-left text-xl font-weight-semibold label">Note details</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                :value="formattedCreatedDate"
                label="Created"
                outlined
                dense
                disabled
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="note.type" label="Type" outlined dense disabled readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="note.foreign_id"
                label="Foreign ID"
                outlined
                dense
                disabled
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="note.type == 'Device'">
            <v-col cols="12" md="6">
              <v-text-field :value="vehicleID" label="Vehicle ID" outlined dense disabled readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="note_settings.title"
                :rules="[v => !!v || 'Title is required']"
                label="Title *"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-textarea
                v-model="note_settings.comments"
                :rules="[v => !!v || 'Comments is required']"
                label="Comments *"
                outlined
                dense
                required
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="note_settings.location" label="Location (optional)" outlined dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="note_settings.priority"
                :items="priorityItems"
                :rules="[v => !!v || 'Priority is required']"
                label="Priority *"
                outlined
                dense
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="note_settings.status"
                :items="statusItems"
                :rules="[v => !!v || 'Status is required']"
                label="Status *"
                outlined
                dense
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="note_settings.claimant"
                :rules="[v => !!v || 'Claimant is required']"
                label="Claimant *"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="note_settings.assigned"
                label="Assigned To (optional)"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-row>

      <v-divider class="mt-4 mb-10"></v-divider>

      <div class="page-title px-4 mb-4 pb-8">
        <save-button :save-action="saveNoteConfig"></save-button>
        <delete-button :delete-action="deleteNote" :type="'note'" :title="`${note.title}`"></delete-button>
      </div>
    </v-card>
  </div>
</template>

<script>
import { getCurrentInstance } from "@vue/composition-api"
import { mapState } from "vuex"
import SaveButton from "../components/SaveButton.vue"
import DeleteButton from "@/views/next-stop/components/DeleteButton.vue"
import store from "@/store"
import globalHelpers from "@/mixins/globalHelpers"

export default {
  name: "NoteConfiguration",

  metaInfo() {
    return {
      title: `Edit Note`,
    }
  },
  components: {
    SaveButton,
    DeleteButton,
  },

  mixins: [globalHelpers],

  data() {
    return {
      priorityItems: [
        {
          text: "Low",
          value: "Low",
        },
        {
          text: "Medium",
          value: "Medium",
        },
        {
          text: "High",
          value: "High",
        },
        {
          text: "Urgent",
          value: "Urgent",
        },
      ],
      statusItems: [
        {
          text: "Open",
          value: "Open",
        },
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "On Hold",
          value: "On Hold",
        },
        {
          text: "Closed",
          value: "Closed",
        },
      ],
      is_loading: true,
      note_settings: {},
    }
  },

  computed: {
    ...mapState({
      note: state => state.selected_note,
      devices: state => state.gtfs.devices,
    }),
    formattedCreatedDate() {
      return this.formatDate(this.note.created, "en-NZ")
    },
    vehicleID() {
      return this.getVehicleId(this.note.foreign_id)
    },
  },

  created() {
    this.is_loading = true
    store.dispatch("getNote", this.noteId).then(() => {
      this.is_loading = false
      this.note_settings = JSON.parse(JSON.stringify(this.note))
    })
  },

  methods: {
    saveNoteConfig() {
      store.dispatch("updateNote", this.note_settings)
    },

    async deleteNote() {
      try {
        await store.dispatch("removeNote", this.note.note_id)
        store.commit("SET_SNACKBAR", {
          text: `This note '${this.note.title}' was deleted`,
          color: "success",
        })

        // Redirect to Notes tab after successful delete
        setTimeout(() => {
          this.$router.push({
            name: "next-stop-notes",
          })
        }, 2000)
      } catch (error) {
        console.log("error", error)
        store.commit("SET_SNACKBAR", {
          text: `This note '${this.note.title}' could not be deleted`,
          color: "error",
        })
      }
    },
    getVehicleId(deviceId) {
      const device = this.devices.find(d => d.device_id === deviceId)

      return device && device.config.vehicle_id ? device.config.vehicle_id : null
    },
  },

  setup() {
    const curr = getCurrentInstance()
    const noteId = curr.proxy.$route.params.id

    if (!noteId) {
      curr.proxy.$router.push({
        name: "next-stop-notes",
      })
    }

    return {
      noteId,
    }
  },
}
</script>

<style scoped></style>
